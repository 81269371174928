.container {
    width: 1460px;
    max-width: 100%;
    padding: 0 10px;
    margin: 0 auto;
}
li {
    list-style-type: none;
}
ul {
    padding-left: 0;
    margin: 0;
}
.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}
.main-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
        .box-logo {
            img {
                width: 187px;
            }
        }
    }
    .center {
        .main-menu {
            .list-menu {
                display: flex;
                align-items: center;
                li {
                    margin-right: 80px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        font-family: Krona One;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 21px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: rgba(0, 29, 45, 0.8);
                        display: block;
                        padding: 10px;
                        transition: 0.3s ease;
                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
    .right {
        .socical-row {
            display: flex;
            align-items: center;
            .item {
                margin-right: 15px;
                &:last-child {
                    margin-right: 0;
                }
                img {
                    width: 40px;
                }
            }
        }
    }
}
.footer {
    background: url(../../pages/Home/images/footer-1.png);
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    background-color: #fff;
    .box-logo {
        position: absolute;
        left: 50%;
        bottom: 20px;
        transform: translateX(-50%);
        img {
            width: 292px;
        }
    }
} 
