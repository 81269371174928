.section-banner {
    background: url("./images/banner-top-2.png");
    padding-bottom: 208px;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 76.94px;
    position: relative;
    z-index: 4;
    .row-content {
        .title {
            padding: 20px 0;
            img {
                width: 390px;
            }
        }
        .desc {
            font-family: Krona One;
            padding: 0 100px;
            font-size: 20px;
            font-weight: 400;
            line-height: 34px;
            letter-spacing: 0.01em;
            text-align: center;
            color: #fff;
            .throught {
                text-decoration: line-through;
            }
            .throught-none {
                color: rgba(0, 0, 0, 1);
                text-decoration: none;
            }
        }
        .box-img {
            img {
                width: 720px;
            }
        }
    }
}
.section-about {
    background: url("./images/banner-body-2.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-top: -150px;
    z-index: 3;
    padding-bottom: 60px;
    .row-content {
        .title {
            font-family: 'Komigo 3D', sans-serif;
            font-size: 60px;
            font-weight: 400;
            line-height: 77px;
            letter-spacing: 0.05em;
            text-align: center;
            color: rgba(17, 141, 214, 1);
            padding-bottom: 80px;
            padding-top: 240px;
        }
        .desc {
            font-family: Krona One;
            font-size: 20px;
            font-weight: 400;
            line-height: 34px;
            letter-spacing: 0.01em;
            text-align: center;
            color: rgba(15, 15, 15, 1);
        }
        .box-img {
            padding-top: 45px;
            margin-bottom: -155px;
            img {
                width: 736px;
            }
        }
        .title-bottom {
            font-family: 'Komigo 3D', sans-serif;
            font-size: 60px;
            font-weight: 400;
            line-height: 77px;
            letter-spacing: 0.05em;
            text-align: center;
            color: rgba(17, 141, 214, 1);
            padding-bottom: 30px;
        }
        .box-img-shark {
            .img-big {
                margin: 0 auto;
                width: 967.11px;
                position: relative;
                img {
                    width: 100%;
                }
                .img-small {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    img {
                        width: 800px;
                    }
                }
            }
        }
    }
}
.section-token {
    padding: 180px 0 100px 0;
    position: relative;
    background: #fff;
    .img-shark {
        img {
            width: 625px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    .row-content {
        .title {
            font-family: 'Komigo 3D', sans-serif;
            font-size: 60px;
            font-weight: 400;
            line-height: 77px;
            letter-spacing: 0.05em;
            text-align: center;
            color: rgba(17, 141, 214, 1);
            padding-bottom: 120px;
        }
        .row {
            .box-img {
                width: fit-content;
                border: 2px solid rgba(51, 68, 106, 1);
                border-radius: 8px;
                padding: 10px;
                transition: 0.3s ease;
                cursor: pointer;
                &:hover {
                    transform: scale(1.1);
                }
                img {
                    width: 188px;
                    margin-bottom: 15px;
                }
                .text {
                    font-family: Krona One;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0.01em;
                    text-align: center;
                    color: rgba(17, 141, 214, 1);
                }
                .number {
                    font-family: Krona One;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0.01em;
                    text-align: center;
                    color: #000;
                }
            }
        }
        .colum:nth-child(1) {
            text-align: left;
            padding-bottom: 100px;
        }
        .colum:nth-child(2) {
            text-align: right;
            padding-bottom: 100px;
        }
        .colum:nth-child(4) .box-img {
            margin-left: auto;
        }
        .colum:nth-child(2) .box-img {
            margin-left: auto;
        }
        .row-contract {
            padding-top: 80px;
            .contract-name {
                border: 1px solid rgba(17, 141, 214, 1);
                font-family: Krona One;
                font-size: 20px;
                font-weight: 400;
                line-height: 34px;
                letter-spacing: 0.01em;
                text-align: left;
                color: rgba(29, 29, 29, 1);
                padding: 15px;
                width: 600px;
                margin: 0 auto;
                border-radius: 6px;
                position: relative;
                .copy-text {
                    position: absolute;
                    top: 50%;
                    right: 5px;
                    transform: translateY(-50%);
                    background: rgba(17, 141, 214, 1);
                    color: #fff;
                    padding: 12px;
                    border-radius: 6px;
                }
            }
        }
    }
}
.columns {
    display: flex;
    flex-wrap: wrap;
}
.w-6 {
    width: 50%;
}
.w-5 {
    width: 40%;
}
.w-7 {
    width: 60%;
}
.section-community {
    padding-bottom: 900px;
    padding-top: 60px;
    .columns {
        align-items: center;
    }
    .row-content {
        .row {
            &.res-line {
                padding-left: 50px;
            }
            .box-img {
                img {
                    width: 100%;
                }
            }
            .title {
                font-family: 'Komigo 3D', sans-serif;
                font-size: 60px;
                font-weight: 400;
                line-height: 77px;
                letter-spacing: 0.05em;
                text-align: center;
                color: rgba(17, 141, 214, 1);
                padding-bottom: 20px;
                text-align: left;
            }
            .desc {
                font-family: Krona One;
                font-size: 20px;
                font-weight: 400;
                line-height: 34px;
                letter-spacing: 0.01em;
                text-align: left;
                color: rgba(29, 29, 29, 1);
                padding-bottom: 20px;
            }
            .socical-row {
                display: flex;
                align-items: center;
                .item {
                    margin-right: 15px;
                    &:last-child {
                        margin-right: 0;
                    }
                    img {
                        width: 80px;
                    }
                }
            }
        }
    }
}
.section-bought {
    background: rgba(66, 172, 234, 1);
    padding: 60px 0;
    position: relative;
    .banner-bottom {
        img {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
        }
    }
    .row-content {
        position: relative;
        z-index: 2;
        .title {
            font-family: 'Komigo 3D', sans-serif;
            font-size: 60px;
            font-weight: 400;
            line-height: 77px;
            letter-spacing: 0.05em;
            text-align: center;
            color: #fff;
            padding-bottom: 20px;
        }
        .item {
            border: 1.87px solid rgba(51, 68, 106, 1);
            padding: 15px;
            background: #fff;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            border-radius: 8px;
            justify-content: space-between;
            margin-bottom: 20px;
            transition: 0.3s ease;
            cursor: pointer;
            &:hover {
                background: #fcd7a2;
                border: 1.87px solid rgb(66, 172, 234);
            }
            .icon {
                width: 180px;
                text-align: center;
                img {
                    width: 145px;
                }
            }
            .text-row {
                width: calc(100% - 200px);
                text-align: left;
                .number {
                    font-family: 'Komigo 3D', sans-serif;
                    font-size: 60px;
                    font-weight: 400;
                    line-height: 77px;
                    letter-spacing: 0.05em;
                    text-align: left;
                    color: rgba(66, 172, 234, 1);
                    padding-bottom: 10px;
                }
                .text {
                    font-family: Krona One;
                    color: rgba(17, 141, 214, 1);
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 41px;
                    letter-spacing: 0.01em;
                    text-align: left;
                    padding-bottom: 10px;
                }
                .desc {
                    color: rgba(29, 29, 29, 1);
                    font-family: Krona One;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 27px;
                    letter-spacing: 0.01em;
                    text-align: left;
                }
            }
        }
    }
}
@keyframes leaves {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    100% {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
    }
}

.treeLeaves {
    animation: leaves 2s ease-in-out infinite alternate;
    -webkit-animation: leaves 2s ease-in-out infinite alternate;
}
@media screen and (max-width: 768px) {
    .main-header .center .main-menu .list-menu {
        display: none;
    }
    .main-header .left .box-logo img {
        width: 120px;
    }
    .main-header .right .socical-row .item img {
        width: 30px;
    }
    .section-banner .row-content .title img {
        width: 250px !important;
    }
    .section-banner .row-content .desc {
        padding: 0 !important;
        font-size: 16px;
        text-decoration: none;
        line-height: 1.8;
    }
    .section-banner .row-content .box-img img {
        width: 350px;
    }
    .section-about .row-content .title {
        padding-top: 50px;
    }
    .section-banner {
        padding-bottom: 150px;
        z-index: 2;
        background-position:top;
    }
    .section-about .row-content .title {
        font-size: 26px;
        padding-bottom: 20px;
    }
    .section-about .row-content .desc {
        font-size: 16px;
    }
    .section-about .row-content .box-img img {
        width: 350px;
    }
    .section-about .row-content .box-img {
        margin-bottom: 0;
    }
    .section-about .row-content .title-bottom {
        font-size: 26px;
        padding-bottom: 20px;
    }
    .section-about .row-content .box-img-shark .img-big .img-small img {
        width: 350px;
    }
    .section-about .row-content .box-img-shark .img-big {
        width: 100%;
    }
    .section-token {
        padding: 60px 0;
    }
    .section-token .row-content .title {
        font-size: 26px;
        padding-bottom: 20px;
    }
    .section-token .img-shark img {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
    }
    .w-6 {
        width: 100%;
    }
    .section-token .row-content .row .box-img {
        width: 100%;
        text-align: center;
    }
    .section-token .row-content .row .box-img img {
        margin: 0 auto;
        margin-bottom: 10px;
    }
    .section-token .row-content .colum:nth-child(1) {
        padding-bottom: 20px;
    }
    .section-token .row-content .colum:nth-child(2) {
        padding-bottom: 20px;
    }
    .section-token .row-content .colum:nth-child(3) {
        padding-bottom: 20px;
    }
    .section-token .row-content .row-contract .contract-name {
        width: 100%;
    }
    .section-token .row-content .row-contract {
        padding-top: 20px;
    }
    .section-bought .row-content .title {
        font-size: 26px;
        padding-bottom: 20px;
    }
    .section-bought .row-content .item {
        display: block;
    }
    .section-bought .row-content .item .text-row {
        width: 100%;
        text-align: center;
    }
    .section-bought .row-content .item .icon {
        width: 120px;
        margin: 0 auto;
    }
    .section-bought .row-content .item .text-row .number {
        text-align: center;
        font-size: 40px;
        line-height: 1.6;
    }
    .section-bought .row-content .item .text-row .text {
        font-size: 18px;
        text-align: center;
        line-height: 1.6;
    }
    .section-bought .row-content .item .text-row .desc {
        font-size: 14px;
        text-align: center;
    }
    .section-bought .row-content .item .icon img {
        width: 120px;
    }
    .w-5 {
        width: 100%;
    }
    .w-7 {
        width: 100%;
    }
    .section-community .columns {
        flex-direction: column-reverse;
    }
    .section-community {
        padding: 60px 0 200px 0;
    }
    .section-community .row-content .row .title {
        font-size: 26px;
        padding-bottom: 20px;
        line-height: 1.6;
        text-align: center;
    }
    .section-community .row-content .row .desc {
        font-size: 14px;
        line-height: 1.6;
        text-align: center;
    }
    .section-about {
        background-position: center;
    }
    .section-community .row-content .row .socical-row {
        justify-content: center;
    }
    .section-community .row-content .row .socical-row .item img {
        width: 40px;
    }
    .section-community .row-content .row.res-line {
        padding-left: 0;
    }
    .section-community .row-content .row .box-img img {
        margin-top: 20px;
    }
    .footer .box-logo img {
        width: 150px;
    }
    .section-token .row-content .row-contract .contract-name {
        font-size: 14px;
    }
    .footer .box-logo {
        display: none !important;
    }
    .section-community .row-content .row .box-img img {
        width: 70%;
    }
}